import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Contato() {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.smtpexpress.com/send",
        {
          subject: `Novo email recebido de ${data.nome}`,
          message: `
<div style="max-width: 600px; margin: 0 auto; padding: 20px; background-color: #ffffff; color: #333333; font-family: Arial, sans-serif; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); border-radius: 8px;">
<div style="display: flex; justify-content: center; align-items: center; text-align: center; height: 100px;">
  <a href="#">
    <img src="https://alpslabor.com.br/assets/images/logo.png" alt="Logo" style="width: auto; height: 50px;">
  </a>
</div>
<br/>
  <main style="margin-top: 30px;">
    <h2 style="color: #555555;">Um novo contato foi feito:</h2>

    <p><strong>Nome:</strong> ${data.nome}</p>
    <p><strong>E-mail:</strong> ${data.email}</p>
    <p><strong>Como conheceu a Alps Labor:</strong> ${data.comoConheceu}</p>
    <p><strong>Mensagem:</strong> ${data.mensagem}</p>
  </main>

  <footer style="margin-top: 30px; text-align: center; color: #999999;">
    <p>
      Esse email foi enviado de <a href="mailto:atendimento@alpslabor.com" style="color: #3b82f6; text-decoration: none;">atendimento@alpslabor.com</a>.
    </p>
    <p style="margin-top: 10px;">&copy; ${new Date().getFullYear()} Alps Labor. All Rights Reserved.</p>
  </footer>
</div>
`,
          sender: {
            name: "Alps Labor",
            email: "atendimentoalpslabor@smtpexpress.email",
          },
          recipients: {
            name: "Alps Labor",
            email: "atendimento@alpslabor.com",
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer 35e8bc2f351819ddcd0ee723eaf0c8948737fe36e7664c8a0a",
          },
        }
      );

      // Wait for 2 seconds
      await new Promise((resolve) => setTimeout(resolve, 2000));

      const secondResponse = await axios.post(
        "https://api.smtpexpress.com/send",
        {
          subject: `Alps Labor - Recebemos sua solicitação!`,
          message: `
            <div style="max-width: 600px; margin: 0 auto; padding: 20px; background-color: #ffffff; color: #333333; font-family: Arial, sans-serif; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); border-radius: 8px;">
            <br/>
              <main style="margin-top: 30px;">
                <h3 style="color: #555555;">:: Este é um email automático ::</h3>
                
                <p>Olá, ${data.nome}!</p>
                <p>Obrigado por enviar sua solicitação.</p>
                <p>As informações foram recebidas e estão em processo de revisão. Em breve retornaremos a sua solicitação.</p>
                <p><Caso tenha extrema urgência na solicitação por favor entre em contato com: comercial2@alpslabor.com.br </p>
                <p>Atenciosamente,</p>
              </main>

              <div style="height: 100px;">
                <a href="#">
                  <img src="https://alpslabor.com.br/assets/images/mail-footer.png" alt="Logo" style="width: auto; height: 100px;">
                </a>
              </div>
            </div>
          `,
          sender: {
            name: "Alps Labor",
            email: "atendimentoalpslabor@smtpexpress.email",
          },
          recipients: {
            name: data.nome,
            email: data.email,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer 35e8bc2f351819ddcd0ee723eaf0c8948737fe36e7664c8a0a",
          },
        }
      );
      toast.success("Email enviado com sucesso!");
      console.log("Email enviado com sucesso:", response.data);
      console.log("Segundo email enviado com sucesso:", secondResponse.data);
    } catch (error) {
      toast.error("Erro ao enviar email.");
      console.error("Erro ao enviar email:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full bg-gray-200 pt-8 pb-8">
      <div className="max-w-[83em] mx-auto p-6 rounded-lg bg-white">
        <h1 className="text-4xl font-bold text-[#951514] mb-2">Contato</h1>
        <p className="text-gray-600 mb-6">
          Um amplo portfólio de produtos originais de alta tecnologia com a
          qualidade das melhores marcas.
        </p>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid grid-cols-1 gap-4"
        >
          <div>
            <label
              htmlFor="nome"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Nome:
            </label>
            <input
              type="text"
              id="nome"
              {...register("nome", { required: "Nome é obrigatório" })}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
            {errors.nome && (
              <span className="text-red-500">{errors.nome.message}</span>
            )}
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              E-mail:
            </label>
            <input
              type="email"
              id="email"
              {...register("email", {
                required: "E-mail é obrigatório",
                pattern: { value: /^\S+@\S+$/i, message: "E-mail inválido" },
              })}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
            {errors.email && (
              <span className="text-red-500">{errors.email.message}</span>
            )}
          </div>

          <div>
            <label
              htmlFor="comoConheceu"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Como conheceu a Alps Labor?:
            </label>
            <select
              id="comoConheceu"
              {...register("comoConheceu", {
                required: "Este campo é obrigatório",
              })}
              className="w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="">Selecione</option>
              <option value="internet">Internet</option>
              <option value="indicacao">Indicação</option>
              <option value="redesSociais">Redes Sociais</option>
              <option value="eventos">Eventos</option>
              <option value="outdoor">Outdoor</option>
              <option value="revista">Revista</option>
              <option value="outros">Outros</option>
            </select>
            {errors.comoConheceu && (
              <span className="text-red-500">
                {errors.comoConheceu.message}
              </span>
            )}
          </div>

          <div>
            <label
              htmlFor="mensagem"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Mensagem:
            </label>
            <textarea
              id="mensagem"
              {...register("mensagem", { required: "Mensagem é obrigatória" })}
              rows={4}
              className="w-full p-2 border border-gray-300 rounded-md"
            ></textarea>
            {errors.mensagem && (
              <span className="text-red-500">{errors.mensagem.message}</span>
            )}
          </div>

          <div></div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="px-8 py-4 bg-[#951514] text-white font-semibold rounded-md hover:bg-[#ac2a2a] transition duration-300"
              disabled={loading}
            >
              {loading ? "Enviando..." : "ENVIAR"}
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
